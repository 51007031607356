import '../asset/css/style.css'
import '../asset/css/table.css';
import { Table, Spin } from "antd";
import React, { useState, useEffect } from "react";
import Constant from '../util/Constant.json';
import { getURL } from '../util/index';
import SiteService from '../service/Sites';

export const AuditTraceTable = (props) => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    

    useEffect(async () => {
        setLoading(true);
        let data = await getAuditData();
        setData(data);
        setLoading(false);

    }, [props]);

    async function getAuditData() {
        let url = new getURL(Constant.URL.GET_AUDIT_DATA);
        url.searchParams.append('date', props.date);
        url.searchParams.append('country', props.country);
        url.searchParams.append('siteId', props.siteId);
        
        // if (localStorage.getItem(`${props.country}monthSiteLastRequestedURL`) !== url.href) {
        //     localStorage.setItem(`${props.country}monthSiteLastRequestedURL`, url.href);
            
        //     let availableData = await SiteService.getAuditData(url.href, {});
        //     return availableData;
        // }
        // else {
        //     console.log(new Error().stack);
        //     throw new Error('Same data stopped');
        // }
        let availableData = await SiteService.getAuditData(url.href, {});
        return availableData;

    }
    const columns = [
        {
            title: 'Time Stamp (GMT)',
            dataIndex: 'timestamp',
            key: 'timestamp',
            ellipsis: true,
            width: 200,
        },
        {
            title: 'Domain',
            dataIndex: 'domain',
            key: 'domain',
            ellipsis: true,
            width: 100
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            ellipsis: true,
        }
    ];

    function getClassName(errorCode) {

        switch (errorCode) {
            case "M":
                return "audit-table-row-cell-error";
            case "E":
                return "audit-table-row-cell-error";
            case "EL":
                return "audit-table-row-cell-error";
            case "EW":
                return "audit-table-row-cell-error";
            case "Y":
                return "audit-table-row-cell-error";
            case "ELW":
                return "audit-table-row-cell-error";
            case "IP":
                return "audit-table-row-cell-error";
            default:
                return "audit-table-row-cell";
        }

    }

    return(
        
       <>   
            {loading ? (
                <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                    <Spin tip="Loading" size="large">
                    </Spin>
                </div>
                
            ): (
                <>
                    {data.length ? (
                        <Table 
                            className="audit-table"
                            rowClassName={(record, index) => getClassName(record.errorCode)}
                            columns={columns} 
                            bordered
                            dataSource={data} 
                            size="small" 
                            scroll={{y: 300}} 
                            pagination={false}
                            
                        />
                    ):(
                        <>
                            <div className="ant-empty ant-empty-normal"><div className="ant-empty-image"><svg className="ant-empty-img-simple" width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0 1)" fill="none" fill-rule="evenodd"><ellipse className="ant-empty-img-simple-ellipse" cx="32" cy="33" rx="32" ry="7"></ellipse><g className="ant-empty-img-simple-g" fill-rule="nonzero"><path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path><path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" className="ant-empty-img-simple-path"></path></g></g></svg></div><div className="ant-empty-description">No Data</div></div>
                        </>
                    )
                    }
                </>
            )}
            
       </>
    );
}

export default AuditTraceTable;