import React from "react";
import { Table } from "antd";
// import DaySites from "./DaySites";
import DaySites from "./ErrorDaySites";

class DayCountries extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            expandedKeys: []
        };

        // console.log("DayCountries :: ", this.props);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.expandedKeys.length === 0 && this.state.expandedKeys.length > 0) {
            this.setState({expandedKeys : []});
        }
    }
    
    render() {
        const country = this.props.country;
        const columns = [
            { title: 'Countries' }
        ];

        return <div className="countryTable">
            <Table
                className="components-table-country-nested"
                tableLayout="fixed"
                bordered="true"
                key={record => record.name}
                showHeader={false}
                columns={columns}
                expandedRowKeys={this.state.expandedKeys}
                onExpand={(expanded, record) => {
                    if (expanded) {
                        this.setState({ expandedKeys: [...this.state.expandedKeys, record] })
                    }
                    else {
                        let list = [...this.state.expandedKeys];
                        list.splice(list.indexOf(record), 1);
                        this.setState({ expandedKeys: list });
                    }
                }}
                expandable={{
                    expandedRowRender: (country) => {
                        return <DaySites country={country} region={this.props.region} selectedDate={this.props.selectedDate} selectedFlags={this.props.selectedFlags} filterData={this.props.filterData} isAPICallNeeded={this.props.isAPICallNeeded} setAuditDetails={this.props.setAuditDetails} />
                    }
                }}
                dataSource={country}
                pagination={false}
                rowKey={(record) => {
                    return record;
                }}
            />
        </div>
    }
}

export default DayCountries;