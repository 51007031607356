import React from "react";
import { Table } from "antd";

import ErrorSites from "./ErrorSites";

class ErrorGeos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            expandedKeys: []
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.expandedKeys.length === 0 && this.state.expandedKeys.length > 0) {
            this.setState({expandedKeys : []});
        }
    }
    
    render() {
        // console.log("ErrorGeos props :: ", this.props);
        const row = this.props.row;

        const columns = [
            { title: 'ErrorGeos' }
        ];

        return <div className="countryTable">
            <Table
                className="components-table-country-nested"
                tableLayout="fixed"
                bordered="true"
                key={record => record.name}
                showHeader={false}
                columns={columns}
                rowExpandable={(record) => {
                    return true;
                }}
                expandedRowKeys={this.state.expandedKeys}
                onExpand={(expanded, record) => {
                    if (expanded) {
                        this.setState({ expandedKeys: [...this.state.expandedKeys, record] })
                    }
                    else {
                        let list = [...this.state.expandedKeys];
                        list.splice(list.indexOf(record), 1);
                        this.setState({ expandedKeys: list });
                    }
                }}
                expandable={{
                    expandedRowRender: (row) => {
                        return <ErrorSites row={row} country={row} region={this.props.region} selectedDate={this.props.selectedDate} filterData={this.props.filterData} setAuditDetails={this.props.setAuditDetails} setColumnName={this.props.setColumnName} columnData={this.props.columnData} showModal={this.props.showModal} handleOk={this.props.handleOk} handleCancel={this.props.handleCancel}/>
                    }
                }}
                dataSource={row}
                pagination={false}
                rowKey={(record) => {
                    return record;
                }}
            />
        </div>
    }
}

export default ErrorGeos;