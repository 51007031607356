import APICall from './api-call';

class ErrorSummaryScreen {

    static async getMonthData(URL, requestOption) {
        try {
            let response = await APICall.request(URL, requestOption);
            return response;
        } catch (error) {
            console.log('Exception occurred while fetching month data', error);
            return {
                message: { regions: [] }
            };
        }

    }
}
export default ErrorSummaryScreen;